import { fontsData } from '@/data/fonts';
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { VideoRenderer } from './worker/video-renderer-worker';
import type { ColorSource } from 'pixi.js'
import { toRaw, watch } from 'vue'
import type { VideoFragment } from '@/modules/SLVideoplayer/types';
import { noop } from 'ts-essentials';
import { decodeImage } from './rive/decode-image';
import type { RenderDetailsDto } from '@/apis/streamladder-api/model'
import { useStickersStore as useOldStickersStore } from '@/store/entity-system/useStickersStore';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import { downloadStickerScreenshot } from '@/lib/downloadStickerImage';
import { copyRef } from '@/store/entity-system/_copyRef';
import { textStyleToCaptionPreset } from '@/components/Captions/v3/textStyleToCaptionPreset'
import * as Sentry from '@sentry/vue'
import { runWebcodecTest } from './worker/webcodec-test';

import { getAudioDecoder } from 'audio-file-decoder';
import { fetchWithRetry } from '@/lib/fetchWithRetry';

interface VideoWorkerManagerProps {

  id: string

  width: number
  height: number
  backgroundColor?: ColorSource
  frames?: VideoFragment[]
  videoUrl?: string

  startMs?: number
  endMs?: number

  previewCanvas?: HTMLCanvasElement

  live?: boolean | undefined
  onDownloadFinished?: (url: string, blob: Blob, fps?: string, speed?: string) => void | undefined
  onDownloadFinishedRaw?: (buffer: ArrayBuffer, fps?: string, speed?: string) => void
  onDownloadProgress?: (status: string) => void | undefined
  onProgress?: (status: string) => void | undefined
  onLog?: (message: string) => void | undefined
  onError?: (message: string) => void | undefined

  renderDetails?: RenderDetailsDto

  startTimeOffset?: number

}

interface WorkerLike {
  postMessage: { (message: any, transfer: Transferable[]): void; (message: any, options?: StructuredSerializeOptions | undefined): void; };
  addEventListener: (type: string, callback: (message: { data: { type: string, status: string, buffer: ArrayBuffer, progress: number, log: string, url: string, clickableAreas: [], fps?: string, speed?: string } }) => void) => void;
  terminate: () => void;
  onerror: ((this: AbstractWorker, ev: ErrorEvent) => any) | null;
}

interface ClickableArea {
  parent: string;
  area: { x: number, y: number, width: number, height: number };
  hoverIcon?: string;
  onMove?: (e: MouseEvent, x: number, y: number) => void;
  onClick?: (e: MouseEvent, x: number, y: number) => void;
  reset?: (e: MouseEvent, x: number, y: number) => void;
  clickIcon?: string;
}


interface OptionsUpdate {
  captionsWrapper: {
    x?: number;
    y?: number;
    scale?: number;
  }
}

export class VideoWorkerManager {

  id: string | null = null;

  options = {};
  worker: WorkerLike;
  canvas: HTMLCanvasElement
  offscreenCanvas?: OffscreenCanvas
  initializeCallback?: () => void | undefined;
  onLog?: (message: string) => void | undefined;
  onError?: (message: string) => void | undefined

  clickableAreas: ClickableArea[] = [];
  capturedArea?: ClickableArea;

  isRendering = false;
  isExporting = false;
  isFinished = false;
  isTerminated = false;

  useRenderDetails = false;

  live = false;

  lastRenderFunction? = noop;

  constructor(props: VideoWorkerManagerProps) {

    this.id = props.id

    const { live, onDownloadProgress, onDownloadFinished, onProgress, onLog, onError, onDownloadFinishedRaw } = props;
    this.live = !!live;

    this.onLog = onLog;
    this.onError = props.onError;

    if (!live) {
      this.worker = new Worker(new URL('./worker/worker.js', import.meta.url), { type: "module" });
    } else {
      this.worker = VideoRenderer();
    }

    this.worker.onerror = (ev: ErrorEvent) => {
      const error = new Error(ev.message);
      error.cause = ev;

      this.handleWorkerError(error, this.onError);
    };
  
    this.canvas = document.createElement('canvas');

    this.canvas.width = props.width;
    this.canvas.height = props.height;
  
    if (!live) {
      this.offscreenCanvas = this.canvas.transferControlToOffscreen();
    }

    this.useRenderDetails = !!props.renderDetails;
    if (props.renderDetails) {

      const videoFragments = [
          { type: 'background', cropData: { zIndex: 0 } }
      ];

      let startMs = Infinity;
      let endMs = 0;

      const fragments = props.renderDetails.segments ?? [];
      fragments.sort((a, b) => (a.startMs ?? 0) - (b.startMs ?? 0));

      for (const fragment of fragments) {
          for (const cropData of fragment.cropData ?? []) {
            videoFragments.push({
              cropData: cropData,
              feedData: cropData.feedData,
              startMs: fragment.startMs,
              endMs: fragment.endMs
            })
          }

          startMs = Math.min(fragment.startMs ?? startMs, startMs);
          endMs = Math.max(fragment.endMs ?? endMs, endMs);
      }

      videoFragments.sort((a, b) => a.cropData.zIndex - b.cropData.zIndex);

      const fonts = [];
      const fontInfo = fontsData.fonts.find(f => f.label === props.renderDetails?.overlay?.captionsObject?.CaptionStyleSettings?.fontFamily);
      if (fontInfo) fonts.push(fontInfo);

      const stickers = (props.renderDetails.overlay?.Stickers as StickerData[] ?? []).map((sticker) => sticker.isTextSticker ? ({ preset: textStyleToCaptionPreset(sticker.variant), ...toRaw(sticker), component: null }) : { ...toRaw(sticker), component: null });

      for (const sticker of stickers) {
        const fontInfo = fontsData.fonts.find(f => f.label === sticker.preset?.font?.fontFamily);
        if (fontInfo) fonts.push(fontInfo);
      }

      const captionStyleSettings = props.renderDetails.overlay?.captionsObject?.captionPreset;
      const baseOptions = props.renderDetails.overlay?.captionsObject?.BaseOptions;
      
      this.options = {
        videoInfo: {
          videoUrl: props.renderDetails.sourceUrl,
        },
        clipInfo: {
          start: startMs,
          end: endMs,
        },
        canvas: this.offscreenCanvas ?? this.canvas,
        fonts: fonts,
        baseOptions: baseOptions,
        frames: videoFragments,
        captions: props.renderDetails.overlay?.Captions ?? [],
        socialStickers: stickers.map(sticker => ({ ref: sticker })),
        stickers: stickers,
        captionSettings: props.renderDetails.overlay?.CaptionsWrapper ?? {},
        live,
        previewCanvas: props.previewCanvas?.transferControlToOffscreen(),
        captionStylesSettings: captionStyleSettings ?? {},
        devicePixelRatio: window.devicePixelRatio,
        startTimeOffset: props.startTimeOffset,
        outputFPS: props.renderDetails.outputFps,
      };
    } else {

      const editorCaptionsStore = useEditorCaptionsStore();
      watch(editorCaptionsStore, () => {
        this.updateOptions();
      })

      const stickersStore = useStickersStore();
      watch(stickersStore.listImageUrls(), () => this.updateOptions())

      const oldStickersStore = useOldStickersStore();
      watch(oldStickersStore.listImageUrls(), () => this.updateOptions())

      const fonts = [];
      const fontInfo = fontsData.fonts.find(f => f.label === editorCaptionsStore.captionPreset?.font?.fontFamily)
      if (fontInfo) fonts.push(fontInfo)
      
      const stickers = []
      const oldSocialStickersStore = useOldStickersStore();
      const socialStickersStore = useStickersStore();
      for (const sticker of [...oldSocialStickersStore.entities, ...socialStickersStore.entities]) {
        if ('imageUrl' in sticker && sticker.imageUrl) {
          const imageElement = new Image();
          imageElement.src = sticker.imageUrl;
          stickers.push(({
            ref: sticker,
            imageBitmap: imageElement,
          }))
        }
        else if (sticker.type === 'text') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.color)
          }))
        } else if (sticker.type === 'brand-kit') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.primaryColor, sticker.secondaryColor)
          }))
        }
      }

      this.options = {
        videoInfo: {
          videoUrl: props.videoUrl,
        },
        clipInfo: {
          start: props.startMs,
          end: props.endMs,
        },
        canvas: this.offscreenCanvas ?? this.canvas,
        fonts: fonts,
        styleOptions: toRaw(editorCaptionsStore.styleOptions),
        baseOptions: toRaw(editorCaptionsStore.baseOptions),
        frames: props.frames,
        captions: toRaw(editorCaptionsStore.captions),
        socialStickers: stickers,
        captionSettings: editorCaptionsStore.captionsWrapper,
        live,
        previewCanvas: props.previewCanvas?.transferControlToOffscreen(),
        captionStylesSettings: toRaw(editorCaptionsStore.captionPreset),
        devicePixelRatio: window.devicePixelRatio,
        startTimeOffset: props.startTimeOffset,
      };
    }
  
    this.worker.addEventListener('message', (message) => {
      if (message.data.type === 'finished') {
        this.isExporting = false;
        this.isFinished = true;
        this.worker.terminate();
        console.log('Export done!', message)
        // downloadArrayBufferAsFile(message.data.buffer, 'videofile' + '.mp4');
        if (onDownloadFinished) {
          const blob = new Blob([message.data.buffer]);
          const url = URL.createObjectURL(blob);
          onDownloadFinished(url, blob, message.data.fps, message.data.speed);
        }
        if (onDownloadFinishedRaw) {
          onDownloadFinishedRaw(message.data.buffer, message.data.fps, message.data.speed);
        }
      }
      else if (message.data.type === 'download-progress') {
        if (onDownloadProgress) onDownloadProgress(Math.round(message.data.progress) + '%')
      }
      else if (message.data.type === 'progress') {
          // don't remove or edit this log! It's used by puppeteer to track the progress of the server side video render
          console.log(`progress=${Math.round(message.data.progress * 100)}`)

          if (onProgress) onProgress(Math.round(message.data.progress * 100) + '%')
      }
      else if (message.data.type === 'initialized') {
        if (this.initializeCallback) this.initializeCallback();
      }
      else if (message.data.type === 'log') {
        if (this.onLog) this.onLog(message.data.log);
      }
      else if (message.data.type === 'decode-image') {
        decodeImage(message.data.url).then(async image => {
          const bitmap = await createImageBitmap(image);
          this.worker.postMessage({ type: 'decode-image', bitmap, url: message.data.url }, [bitmap]);
        })
      }
      else if (message.data.type === 'clickable-areas') {
        this.clickableAreas = message.data.clickableAreas;
      }
      else if (message.data.type === 'error') {
        this.terminate();
        this.handleWorkerError(new Error(message.data.log), this.onError)
      } else if (message.data.type === 'decode-audio') {
        this.decodeAudio(message.data.url).then((data) => {
          this.worker.postMessage({ type: 'decoded-audio', url: message.data.url, data }, data.rawData.map(data => data.buffer));
        }).catch(error => {
          console.error('error decoding audio');
          this.handleWorkerError(error, this.onError)
          throw error;
        });
      }
    });
  }

  getHoveredClickableArea(e: MouseEvent) {
    if (e.target && e.target instanceof HTMLElement) {
      const rect = e.target.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      for (const clickable of this.clickableAreas) {
        if (x >= clickable.area.x && x <= clickable.area.x + clickable.area.width) {
          if (y >= clickable.area.y && y <= clickable.area.y + clickable.area.height) {
            return { clickable, x, y }
          } 
        }
      }

      return { clickable: null, x, y }
    }

    return { clickable: null, x: 0, y: 0 };
  }

  canvasHover(e: MouseEvent) {
    const { clickable: currentHover, x, y } = this.getHoveredClickableArea(e);
    
    if (this.capturedArea) {
      this.capturedArea.onMove?.(e, x, y);
      return;
    }

    if (currentHover) {
      if (currentHover.hoverIcon) {
        this.canvas.style.cursor = currentHover.hoverIcon;
      }
      currentHover.onMove?.(e, x, y);
    }
    else {
      this.canvas.style.cursor = 'unset';
    }
  }

  canvasClick(e: PointerEvent) {
    const { clickable: currentClicked, x, y } = this.getHoveredClickableArea(e);
    if (currentClicked?.onClick) currentClicked.onClick(e, x, y);

    if (currentClicked?.clickIcon && e.buttons > 0) {
      this.canvas.style.cursor = currentClicked.clickIcon;
    }

    for (const clickable of this.clickableAreas) {
      if (currentClicked === clickable) clickable.onClick?.(e, x, y);
      else if (!currentClicked || currentClicked.parent !== clickable.parent) clickable.reset?.(e, x, y);
    }

    if (currentClicked) {
      this.canvas.setPointerCapture(e.pointerId);
      this.capturedArea = currentClicked;
    }
  }

  handleWorkerError(ev: Error, callback: ((message: string) => void) | undefined){

    // don't remove or edit this log! It's used by puppeteer to track the error of the server side video render
    console.error('Error from video worker: ' + ev.message);

    Sentry.captureException(new Error('WebCodec Error: ' + ev.message));

    if (callback) {
      callback(ev.message);
    }
  }

  // this actually starts the video exporting:
  // reading the source video file, rendering all the frames and generating the output video file
  async startExporting() {
    const transferables: Transferable[] = [this.offscreenCanvas];

    // @ts-ignore
    if (this.options.previewCanvas) {
      // @ts-ignore
      transferables.push(this.options.previewCanvas);
    }

    try {

      this.isFinished = false;
      const promises = [];

      if (!this.useRenderDetails) {
        const oldSocialStickerStore = useOldStickersStore();
        const newSocialStickerStore = useStickersStore();
        this.options.socialStickers = [...oldSocialStickerStore.entities, ...newSocialStickerStore.entities]
          .map(sticker => ({ ref: toRaw(copyRef(sticker)), preset: null, }));
      }

      // const captionStyleSettingsEntries = Object.keys(captionStylesSettings);

      for (const sticker of this.options.socialStickers) {

        if (sticker.ref.type === 'custom') {
          const downloadCustomSticker = async () => {
            const image = new Image();
            image.src = 'https://wsrv.nl/?url=' + encodeURI(sticker.ref.imageUrl);
            image.crossOrigin = 'anonymous';
            await image.decode();
            const bmp = await createImageBitmap(image);
            sticker.imageBitmap = bmp;
          }
          promises.push(downloadCustomSticker())
        }
        else if (sticker.ref.type !== 'giphy'){
          const downloadScreenshot = async () => {
            const arrayBuffer = await downloadStickerScreenshot(sticker.ref, this.canvas.width / (sticker.ref.area.width * this.canvas.width), 1080);
            const bmp = await createImageBitmap(new Blob([ arrayBuffer ]));
            sticker.imageBitmap = bmp;
          }

          promises.push(downloadScreenshot())
        }
      }

      await Promise.all(promises);

    } catch(e) {
      console.error('error downloading stickers: ', e)
    }

    const webcodecTestResults = await runWebcodecTest();

    this.worker.postMessage({ type: 'start', options: this.options, webcodecTestResults }, transferables);

    this.isExporting = true;

    // window.onbeforeunload = () => {
    //   this.terminate();
    //   for (let ix = 0; ix < 100_000; ix ++) {}
    // };
  }

  // this sends all the options to the worker to initialize it for realtime rendering the frames without creating a video file
  initializeForPreview(callback: () => void) {
    this.initializeCallback = callback;
    this.worker.postMessage({ type: 'initialize', options: this.options }, [this.offscreenCanvas]);
  }


  resize(width: number, height: number) {
    this.worker.postMessage({ type: 'resize', width, height });
  }

  // used for video preview
  // the source video frames is the input
  renderVideoFrames(frames: Map<string, VideoFragment>, timestamp: number, width: number, height: number) {
    if (this.isRendering) return;

    this.isRendering = true;
    this.lastRenderFunction = () => this.worker.postMessage({ type: 'render-frame', frames: frames, timestamp, width: width, height: height });
    this.lastRenderFunction();
    this.isRendering = false;
  }

  updateOptions({ captionsWrapper }: OptionsUpdate = { captionsWrapper: { } }) {

    const editorCaptionsStore = useEditorCaptionsStore();
    const stickers = [];

    if (this.live) {
      const oldSocialStickerStore = useOldStickersStore();
      const newSocialStickerStore = useStickersStore();
      for (const sticker of [...oldSocialStickerStore.entities, ...newSocialStickerStore.entities]) {
        if ('imageUrl' in sticker && sticker.imageUrl) {
          const imageElement = new Image();
          imageElement.src = sticker.imageUrl;
          stickers.push(({
            ref: sticker,
            imageBitmap: imageElement,
          }))
        } else if (sticker.type === 'text') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.color)
          }))
        } else if (sticker.type === 'brand-kit') {
          stickers.push(({
            ref: sticker,
            preset: textStyleToCaptionPreset(sticker.variant, sticker.primaryColor, sticker.secondaryColor)
          }))
        }
        else if (sticker.type === 'giphy') {
          stickers.push(({
            ref: sticker
          }))
        }
      }
    }

    // editorCaptionsStore.captionPreset.font.highlightColor = editorCaptionsStore.styleOptions.data.highlightColor;
    // editorCaptionsStore.captionPreset.font.color = editorCaptionsStore.styleOptions.data.baseColor;
    
    this.worker.postMessage({ 
      type: 'update-options', 
      options: {
        captions: toRaw(editorCaptionsStore.captions),
        captionSettings: { ...toRaw(editorCaptionsStore.captionsWrapper), ...captionsWrapper },
        captionStylesSettings: toRaw(editorCaptionsStore.captionPreset),
        socialStickers: stickers,
        baseOptions: toRaw(editorCaptionsStore.baseOptions),
      } 
    });
  }

  async decodeAudio(fileUrl: string) {
    console.log('Decoding audio from: ' + fileUrl);
    console.time('decodeAudio')

    const blob = await (await fetchWithRetry(fileUrl)).blob();

    let decoder;
    try {
      decoder = await getAudioDecoder('/decode-audio.wasm', new File([blob], "audio"));
    } catch(e) {
      if (`${e}`.includes('Failed to locate audio stream')) {
        return {
          rawData: []
        };
      }
      else {
        throw e;
      }
    }

    console.timeEnd('decodeAudio')
    if (!decoder) return {
      rawData: []
    };

    const channelData = [];

    // decode audio from a low, negative timestamp, because otherwise we miss audio in trimmed videos. (the first audio samples have a negative timestamp)
    const interleavedSamples = decoder.decodeAudioData(-99999999, -1, { multiChannel: true });

    const sampleCount = interleavedSamples.length / decoder.channelCount;

    for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
      channelData[channelNr] = new Float32Array(sampleCount);
    }

    let offset = 0;
    for (let sampleNr = 0; sampleNr < sampleCount; sampleNr++) {
      for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
        channelData[channelNr][sampleNr] = interleavedSamples[offset++];
      }
    }

    // uncomment this to play the raw audio.


    // const audioContext = new window.AudioContext();
    // const audioBuffer = audioContext.createBuffer(decoder.channelCount, sampleCount, decoder.sampleRate);

    // for (let channelNr = 0; channelNr < decoder.channelCount; channelNr++) {
    //   audioBuffer.copyToChannel( channelData[channelNr], channelNr);
    // }

    // // Step 3: Create an AudioBufferSourceNode to play the audio
    // const audioSource = audioContext.createBufferSource();
    // audioSource.buffer = audioBuffer;
  
    // // Connect the source to the AudioContext destination (the speakers)
    // audioSource.connect(audioContext.destination);
  
    // // Play the sound (start)
    // audioSource.start();


    decoder.dispose();

    return {
        sampleRate: decoder.sampleRate,     // Sample rate of the audio
        duration: decoder.duration,         // Duration of the audio
        channels: decoder.channelCount, // Number of channels
        rawData: channelData        // Array of Float32Arrays containing raw samples for each channel
      };
  }

  terminate() {
    this.isTerminated = true;
    this.worker.postMessage({
      type: 'terminate'
  });
  }
}
